import React, { useMemo } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { mergePrismicPreviewData } from "gatsby-source-prismic";
import { Row, Col } from "styled-bootstrap-grid";

const Page = styled.div`
  margin: 117px auto 0 auto;
  // max-width: 1400px;

  @media (max-width: 767px) {
    margin: 75px 0 0 0;
  }

  & h2 {
    text-align: center;
    margin-bottom: calc(75px - 1em);

    @media (max-width: 767px) {
      margin-bottom: 25px;
    }
  }

  & .text-column {
    margin-bottom: calc(150px - 1em);

    & p:first-of-type {
      margin-top: 0;
    }

    @media (max-width: 767px) {
      margin-bottom: 50px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    & .social-links {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      a {
        font-size: 20px;
        line-height: 28px;

        text-decoration: none;

        margin-right: 20px;

        &:last-of-type {
          margin: 0;
        }

        @media (max-width: 1000px) {
          font-size: 18px;
          line-height: 26px;
        }
      }
    }
  }

  & .press-title {
    text-align: center;
  }

  & .biography {
    margin: 0 0 3em 0;
  }

  & .press-text {
    & a {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;

      text-decoration: underline;
      text-decoration-color: #818181 !important;
    }
  }

  & p:last-of-type {
    margin: 0;
  }

  & .press-link {
    margin: 0 0 2em 0;
  }
`;

// Returns true if we're in a browser, false otherwise. This will help guard
// against SSR issues when building the site.
const IS_BROWSER = typeof window !== "undefined";

const PrivacyPolicy = ({ data: staticData }) => {
  const data = useMemo(() => {
    // If we're not in a browser (i.e. we're in SSR) or preview data has not been
    // set, use the non-preview static data.
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData;

    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    });
  }, [staticData]);

  return (
    <Page>
      <Helmet title="Privacy Policy – A Delicate Sight" />
      <Row justifyContent="around">
        <Col col={12} md={6}>
          <div className="text-column">
            <h2>Privacy Policy</h2>
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: data.prismicPrivacyPolicy.data.privacy_policy.html,
              }}
            />
          </div>
        </Col>
      </Row>
    </Page>
  );
};

export const query = graphql`
  {
    prismicPrivacyPolicy {
      data {
        privacy_policy {
          html
        }
      }
    }
  }
`;

export default PrivacyPolicy;
